<template>
  <nav class="fixed-navbar bg-primary">
    <div class="container items-center">
      <div class="dropdown">
        <button class="btn btn-outline btn-accent normal-case text-xl btn-largo" @click="toggleDropdown">
          navve.studio
        </button>
        <ul class="shadow menu dropdown-content bg-base-100 rounded-box dropdown-largo" v-show="isDropdownOpen">
          <li><a href="/videochamada">Chamada de vídeo</a></li>
          <li><a href="/intercom">Intercom</a></li>
          <li><a href="/diretor2">Diretor</a></li>
          <li><a href="/whip">Whip-Receptor<small style="color:red">novo</small></a></li>
          <li><a target="_blank" href="https://dev.navve.studio/alpha/whip2.html">Whip-Mobile<small style="color:red">novo</small></a></li>
          <li><a target="_blank" href="https://whip-send-signal.vercel.app/">Whip-Transmissor-Broadcast<small style="color:red">novo</small></a></li>
          <li>
            <button style="display:none; font-size: 1rem !important" class="btn btn-outline btn-accent normal-case text-xl"
              @click="copyToClipboard">
              Copiar link
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      isDropdownOpen: false,
      showCopyButton: false,
    };
  },
  created() {
    this.checkRoomParam();
    window.addEventListener("popstate", this.checkRoomParam); // adiciona o evento popstate para detectar mudanças na URL
  },
  unmounted() {
    window.removeEventListener("popstate", this.checkRoomParam); // remove o evento popstate
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    copyToClipboard() {
      const urlParams = new URLSearchParams(window.location.search);
      const roomParam = urlParams.get("room");
      if (roomParam === null) {
        alert("Você deve entrar em uma sala primeiro.");
        return;
      }
      const url = `${window.location.origin}/?room=${roomParam}`;
      navigator.clipboard.writeText(url);
      alert("URL da Sala copiada para a área de transferência.");
    },
    checkRoomParam() {
      const urlParams = new URLSearchParams(window.location.search);
      const roomParam = urlParams.get("room");
      this.showCopyButton = !!roomParam;
    },
  },
};
</script>
<style scoped>
.fixed-navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center; /* Alinha o container ao centro */
  width: 100%;
}
.btn-largo {
  padding-left: 7rem;
  padding-right: 7rem;
}
.dropdown-largo {
  width: 100%;
}
.dropdown-largo li {
  padding-left: 1rem;
  padding-right: 1rem;
}
.dropdown-largo li button {
  width: 100%;
  text-align: left;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
}
</style>
