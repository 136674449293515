<template>
  <div class="dark">
    <NavbarComponent />
    <div
      id="card"
      class="flex items-center justify-center min-h-screen px-4"
      v-if="!showIframe"
    >
      <div class="card-container centered">
        <form
          style="max-width: 400px"
          class="card w-full max-w-lg bg-primary text-primary-content rounded-2xl shadow-xl"
          @submit.prevent="showIframe = true"
        >
          <div class="card-body text-justify">
            <h2 class="card-title text-3xl font-semibold mb-6">
              Entrar na sala de vídeo chamadas
            </h2>
            <div class="mb-4">
              <label
                class="block text-gray-500 font-bold mb-1"
                for="inline-full-name"
              >
                Selecione o nome da sala
              </label>
              <select
                class="input input-bordered input-success w-full max-w-xs"
                id="inline-full-name"
                v-model="roomName"
                required
              >
                <option value="" disabled>Selecione uma sala</option>
                <option value="sala1">Sala 1</option>
                <option value="sala2">Sala 2</option>
                <option value="sala3">Sala 3</option>
                <!-- Adicione mais opções de sala conforme necessário -->
              </select>
            </div>
            <div class="card-actions justify-end">
              <button
                class="btn btn-outline btn-accent btn-block rounded-md hover:bg-opacity-80 focus:outline-none focus:ring focus:ring-primary-500"
                type="submit"
              >
                Entrar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="iframe-container mt-12" v-if="showIframe">
      <div class="iframe-left">
        <!---->
        <iframe
          :src="
            '/alpha/index.html?room=' +
            roomName +
            '&remote&label&quality=2&hidetranslate&maxbandwidth&viewg&stereo'
          "
          allow="autoplay;camera;microphone;fullscreen;picture-in-picture;display-capture;midi;geolocation;"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import NavbarComponent from "../components/NavbarComponent.vue";

export default {
  components: {
    NavbarComponent,
  },
  data() {
    return { showIframe: false, roomName: "" };
  },
};
</script>
<style scoped>
body {
  margin: 0;
}
input {
  width: 100%;
}
.centered {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-container {
  align-content: space-around;
  margin-top: 12vh;
  min-width: 350px;
}
.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.iframe-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh); /* altura total da janela menos a altura da navbar */
  width: 100vw;
  padding-top: 60px;
}
.iframe-left {
  flex: 1;
}
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
