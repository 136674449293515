<!-- src/App.vue -->

<template>
  <div id="app" class="dark">
    <video-intro
      v-if="showVideo"
      @video-ended="showVideo = false"
    ></video-intro>
    <router-view v-else />
  </div>
</template>

<script>
import VideoIntro from "./components/VideoIntro.vue";

export default {
  name: "App",
  components: {
    VideoIntro,
  },
  data() {
    return {
      showVideo: false,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Estilos adicionais para o componente de vídeo */
.video-intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: black;
}

.video-intro video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
