import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './index.css'; // Se estiver usando Tailwind CSS
import 'daisyui/dist/full.css'; // Se estiver usando DaisyUI
import './registerServiceWorker'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faLock, faUnlock);

const app = createApp(App);

app.component("font-awesome-icon", FontAwesomeIcon)
app.use(router);
app.mount('#app');
