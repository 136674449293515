<!-- src/components/VideoIntro.vue -->

<template>
  <div class="video-intro">
    <video ref="videoElement" @ended="onVideoEnd" autoplay muted playsinline>
      <source :src="videoSource" type="video/mp4" />
      Seu navegador não suporta a tag de vídeo.
    </video>
  </div>
</template>

<script>
export default {
  name: "VideoIntro",
  data() {
    return {
      videoSource: "",
    };
  },
  methods: {
    onVideoEnd() {
      this.$emit("video-ended");
    },
    setVideoSource() {
      const isMobile = window.innerWidth <= 768;
      this.videoSource = isMobile ? "/logoreve.mp4" : "/desk2.mp4";
    },
  },
  mounted() {
    this.setVideoSource();
    const video = this.$refs.videoElement;
    video.play();
  },
};
</script>
<style scoped>
.video-intro {
  /* Estilize o componente de vídeo conforme necessário */
}
</style>
