<template>
  <div class="dark">
    <NavbarComponent />
    <div
      id="card"
      class="flex items-center justify-center min-h-screen px-4"
      v-if="!showIframe"
    >
      <div class="card-container centered">
        <form
          style="max-width: 400px"
          class="card w-full max-w-lg bg-primary text-primary-content rounded-2xl shadow-xl"
          @submit.prevent="showIframe = true"
        >
          <div class="card-body text-justify">
            <h2 class="card-title text-3xl font-semibold mb-6">
              Entrar na sala do diretor
            </h2>
            <div class="mb-4">
              <label
                class="block text-gray-500 font-bold mb-1"
                for="inline-full-name"
              >
                Insira o nome da sala
              </label>
              <input
                class="input input-bordered input-success text-black w-full max-w-xs"
                id="inline-full-name"
                type="text"
                v-model="roomName"
                pattern="^[a-zA-Z0-9_]+$"
                required
              />
            </div>
            <div class="card-actions justify-end">
              <button
                class="btn btn-outline btn-accent btn-block rounded-md hover:bg-opacity-80 focus:outline-none focus:ring focus:ring-primary-500"
                type="submit"
              >
                Entrar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="iframe-container mt-12" v-if="showIframe">
      <div class="iframe-left">
        <div class="float-btn-container relative">
          <button
            @mousedown="drag"
            @mouseup="endDrag"
            @click.stop="showDropdown = !showDropdown"
            class="float-btn rounded-full bg-blue-500 text-white p-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <i class="material-icons">Links</i>
          </button>
          <div
            class="dropdown absolute right-0 bottom-full bg-white shadow-md rounded mt-2 w-56"
            v-show="showDropdown"
          >
            <ul class="list-none">
              <li v-for="(link, index) in dropdownLinks" :key="index">
                <a
                  href="#"
                  @click="copyLinkToClipboard(link)"
                  class="block py-2 px-4 text-gray-800 hover:bg-gray-200 cursor-pointer"
                >
                  {{ link.label }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <iframe
          :src="
            '/alpha/index.html?director=' +
            roomName +
            '&sstype=3&hidetranslate&cleandirector&sl&label=diretor:'
          "
          allow="autoplay;camera;microphone;fullscreen;picture-in-picture;display-capture;midi;geolocation;"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import NavbarComponent from "../components/NavbarComponent.vue";

export default {
  components: {
    NavbarComponent,
  },
  data() {
    const domain = window.location.origin;
    return {
      showIframe: false,
      roomName: "",
      showDropdown: false,
      dropdownLinks: [
         {
          label: "Link 1",
          url: `${domain}/alpha/index.html?scene&room={nomedasala}`,
        },
        {
          label: "Link 2",
          url: `${domain}/alpha/index.html?scene=1&room={nomedasala}`,
        },
        {
          label: "Link Alta",
          url: `${domain}/alpha/index.html?scene=studio&room={nomedasala}&bitrate=9000&vbr&scale=100&fps=30&codec=h264&buffer=500`,
        },
        {
          label: "Convidado",
          url: `${domain}/videochamada?&room={nomedasala}`,
        },
      ],
    };
  },

  methods: {
    copyLinkToClipboard(link) {
       const linkWithRoomName = link.url.replace("{nomedasala}", this.roomName);
      navigator.clipboard.writeText(linkWithRoomName).then(() => {
        alert("Link copiado para a área de transferência!");
      });
    },
    drag(event) {
      const button = event.currentTarget.closest('.float-btn-container');
      const offsetX = event.clientX - button.getBoundingClientRect().left;
      const offsetY = event.clientY - button.getBoundingClientRect().top;

      const move = (e) => {
        button.style.left = e.clientX - offsetX + "px";
        button.style.top = e.clientY - offsetY + "px";
      };

      document.addEventListener("mousemove", move);
      document.addEventListener(
        "mouseup",
        () => {
          document.removeEventListener("mousemove", move);
        },
        { once: true }
      );
    },
    endDrag() {
      document.removeEventListener("mousemove", this.move);
    },
  },
};
</script>
<style scoped>
.float-btn:focus {
  outline: none;
}
.float-btn-container {
  position: fixed;
  bottom: 200px;
  right: 200px;
  z-index: 2000;
}
.float-btn {
  border-radius: 5%;
  background-color: #3b82f6;
  color: white;
  padding: 10px 14px;
  border: none;
  cursor: pointer;
  font-size: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: fixed;
  cursor: move;
  z-index: 2000;
}
.float-btn:focus {
  outline: none;
}
.dropdown {
  position: absolute;
  right: 0;
  bottom: 100%;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 8px 0;
  margin-bottom: 8px;
  z-index: 2000;
}
.dropdown a {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  color: #374151;
  cursor: pointer;
}
.dropdown a:hover {
  background-color: #f3f4f6;
}
body {
  margin: 0;
}
.float-btn {
  position: fixed;
}
input {
  width: 100%;
  color: aquamarine !important;
  background:  black !important;
}
input::target-text {
 
  color: aquamarine !important;
  background:  black !important;
}
.centered {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-container {
  align-content: space-around;
  margin-top: 12vh;
  min-width: 350px;
}
.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.iframe-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh);
  width: 100vw;
  padding-top: 60px;
}
.iframe-left {
  flex: 1;
}
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
