import { createRouter, createWebHistory } from 'vue-router';
import SalaVideoChamadas from '../views/PageSala.vue';
import WhipConect from '../views/PageWhip.vue';
import DiretorControl from '../views/PageDiretor.vue';
import PageDirector from '../views/PageSalaDirector.vue';
import SalaPredef from '../views/PagePredev.vue';
import Playerok from '../views/PagePlayer.vue';
import Blau from '../views/PageBlau.vue';
import Mixer from '../views/PageMixer.vue';
import ViewLink from '../views/PageViewLink.vue';
import AcesaLink from '../views/PageTelaAcesa.vue';
import InterCom from '../views/PageComm.vue';
import LandingPage from '../views/LandingPage.vue';
const routes = [
  {
    path: '/',
    redirect: '/land'
  },
  {
    path: '/land',
    name: 'LandingPage',
    component: LandingPage,
  },
  {
    path: '/videochamada',
    name: 'SalaVideoChamadas',
    component: SalaVideoChamadas,
  },
  {
    path: '/whip',
    name: 'WhipConect',
    component: WhipConect
  },
  {
    path: '/intercom',
    name: 'InterCom',
    component: InterCom,
  },
  {
    path: '/acesa',
    name: 'AcesaLink',
    component: AcesaLink,
  },
  {
    path: '/diretor2',
    name: 'DiretorAlpha',
    component: DiretorControl,
  },
  {
    path: '/diretorparam',
    name: 'Parametros',
    component: PageDirector,
  },
  {
    path: '/predef',
    name: 'SalaPredef',
    component: SalaPredef,
  },
  {
    path: '/playout',
    name: 'Playerok',
    component: Playerok,
  },
  {
    path: '/blau',
    name: 'Blau',
    component: Blau,
  },
  {
    path: '/mixer',
    name: 'mixer',
    component: Mixer,
  },
  {
    path: '/viewlink',
    name: 'ViewLink',
    component: ViewLink,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
