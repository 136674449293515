
<template>
  <div class="dark">
    <NavbarComponent />
    <div class="background-image" v-show="!showIframe"></div>
    <div id="card" class="flex items-center justify-center min-h-screen px-4" v-if="!showIframe">
      <div class="card-container centered">
        <form
          style="max-width: 400px"
          class="card w-full max-w-lg bg-primary text-primary-content rounded-2xl shadow-xl"
          @submit.prevent="submitForm"
        >
          <div class="card-body text-justify">
            <h2 class="card-title text-3xl font-semibold mb-6">
              Receba uma transmissao whip. insira um nome para sua chave
            </h2>
            <div class="mb-4">
              <label class="block text-gray-500 font-bold mb-1" for="inline-full-name">
                Insira o nome da sala
              </label>
              <div class="flex">
                <input style="background-color:black !important;"
                  class="input input-bordered text-cyan-400 bg-black input-success w-full max-w-xs"
                  id="inline-full-name"
                  type="text"
                  v-model="roomName"
                  pattern="^[a-zA-Z0-9_]+$"
                  required
                />
                <button class="btn btn-outline btn-accent rounded-md hover:bg-opacity-80 focus:outline-none focus:ring focus:ring-primary-500 ml-2" type="button" @click="generateRandomName">
                  Gerar Sala
                </button>
              </div>
            </div>
            <div class="card-actions justify-end">
              <button class="btn btn-outline btn-accent btn-block rounded-md hover:bg-opacity-80 focus:outline-none focus:ring focus:ring-primary-500 ml-2" type="submit">
                Entrar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="iframe-container mt-12" v-if="showIframe">
      <div class="iframe-left">
        <iframe :src="iframeSrc" allow="autoplay;camera;microphone;fullscreen;picture-in-picture;display-capture;midi;geolocation;"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarComponent from "../components/NavbarComponent.vue";

const adjectives = [
  "amarelo",
  "azul",
  "branco",
  "cinza",
  "navve",
  "feliz",
  "frio",
  "quente",
  "leve",
  "pesado",
  "luz",
  "verde",
];
const nouns = [
  "abacaxi",
  "amor",
  "casa",
  "cavalo",
  "comida",
  "corrida",
  "floresta",
  "futebol",
  "lua",
  "mar",
  "musica",
  "sol",
];

export default {
  components: {
    NavbarComponent,
  },
  data() {
    return {
      showIframe: false,
      roomName: "",
      iframeSrc: "",
    };
  },
  created() {
    this.checkWhipParam();
  },
  methods: {
    checkWhipParam() {
      const urlParams = new URLSearchParams(window.location.search);
      const whipParam = urlParams.get("whip");
      if (whipParam) {
        this.roomName = whipParam;
        this.submitForm();

        const copyButton = document.createElement("button");
        copyButton.innerHTML = "Copiar URL da Sala";
        copyButton.className = "btn btn-outline btn-accent normal-case text-xl ml-2";
        copyButton.onclick = () => {
          const url = `${window.location.origin}/?hidemenu&whip=${this.roomName}`;
          navigator.clipboard.writeText(url).then(() => {
            alert("URL da Sala copiada para a área de transferência.");
          });
        };

        const navbar = document.querySelector(".dropdown");
        if (navbar) {
          navbar.appendChild(copyButton);
        }
      }
    },
    generateRandomName() {
      const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
      const noun = nouns[Math.floor(Math.random() * nouns.length)];
      const randomDigits = Math.floor(Math.random() * 100).toString().padStart(2, "0");
      const randomName = `${adjective}${noun}${randomDigits}`.toLowerCase();
      this.roomName = randomName;
    },
    submitForm() {
      this.iframeSrc = `https://dev.navve.studio/alpha/?hidemenu&whip=${this.roomName}`;

      setTimeout(() => {
        this.showIframe = true;
      }, 0);

      const newPath = `/whip/?whip=${this.roomName}`;
      if (window.location.pathname + window.location.search !== newPath) {
          history.pushState({}, null, newPath);
      }
    },
  },
};
</script>

<style scoped>
body {
  margin: 0;
}
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("");
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  z-index: -1;
}
input {
  width: 100%;
}
.centered {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-container {
  align-content: space-around;
  margin-top: 12vh;
  min-width: 350px;
}
.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.iframe-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh);
  width: 100vw;
  padding-top: 60px;
}
.iframe-left {
  flex: 1;
}
iframe {
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 640px) {
    .centered {
        top: 40%;
    }

    .card-container {
        margin-top: 6vh;
    }
}
</style>
