
<template>
  <div class="container">
    <NavbarComponent id="navbar" />
    <div class="landing-page">
      <h1 class="welcome-text">Bem-vindo ao navve.studio</h1>
      <p class="brief-description">Desfrute de videochamadas de alta qualidade com baixa latência.</p>
      <div class="features-grid">
      <div class="feature-box" v-if="showVideoChamada" @click="$router.push('/videochamada')">
          <span class="feature-icon">🎥</span>
          <span class="feature-text">Video Chamadas</span>
          <p class="feature-description">Alta qualidade com baixa<br> latência.</p>
        </div>
          <div class="feature-box" @click="$router.push('/diretor2')">
        <span class="feature-icon">🎬</span>
        <span class="feature-text">Diretor</span>
        <p class="feature-description">Controle completo da chamada, <br> envie seu sinal para seu sistema de transmissão. </p>
       
      </div>
      <div class="feature-box" @click="openExternalLink('https://dev.navve.studio/alpha/whip2.html')">
    <span class="feature-icon">🍄</span>
    <span class="feature-text">Send Whip</span>
    <p class="feature-description">Envie sinal de video usando o protocolo whip,<br> alta qualidade e performace.</p>
</div>

        <div class="feature-box" v-if="showWhipConect" @click="$router.push('/whip')">
          <span class="feature-icon">🚀</span>
          <span class="feature-text">Reciver - Whip Server</span>
          <p class="feature-description">Receba sinal usando o protocolo whip.</p>
        </div>
         
        <div class="feature-box" v-if="showInterCom" @click="$router.push('/intercom')">
          <span class="feature-icon">📞</span>
          <span class="feature-text">InterCom</span>
          <p class="feature-description">Comunicacao de voz e retorno de video para sua produção.</p>
        
        </div>
       
   
      <div class="feature-box" v-if="OffDisplay" @click="$router.push('/diretorparam')">
        <span class="feature-icon">⚙️</span>
        <span class="feature-text">Parâmetros</span>
      </div>
      <div class="feature-box" v-if="OffDisplay" @click="$router.push('/predef')">
        <span class="feature-icon">📅</span>
        <span class="feature-text">Sala Predefinida</span>
      </div>
      <div class="feature-box" v-if="OffDisplay" @click="$router.push('/playout')">
        <span class="feature-icon">▶️</span>
        <span class="feature-text">Player</span>
      </div>
      <div class="feature-box" v-if="OffDisplay" @click="$router.push('/blau')">
        <span class="feature-icon">🔍</span>
        <span class="feature-text">Blau</span>
      </div>
      <div class="feature-box" v-if="OffDisplay" @click="$router.push('/mixer')">
        <span class="feature-icon">🎶</span>
        <span class="feature-text">Mixer</span>
      </div>
      <div class="feature-box" v-if="OffDisplay" @click="$router.push('/viewlink')">
        <span class="feature-icon">🔗</span>
        <span class="feature-text">ViewLink</span>
      </div>
    </div>
  </div>
   </div>
   

</template>

<script>
import NavbarComponent from '@/components/NavbarComponent.vue';


export default {
  name: 'LandingPage',
  components: {
    NavbarComponent
  },
  data() {
    return {
      showVideoChamada: true,
      showWhipConect: true,
      showInterCom: true,
      showOffDisplay: false,
    }
  },
    methods: {
    openExternalLink(url) {
      window.open(url, '_blank');
    }
    // Você pode adicionar mais métodos aqui se precisar
  }
}
</script>
<style scoped>
  .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 60px; /* considering the navbar's height */
}

#navbar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.landing-page {
  max-width: 1200px; /* max-width for desktop centralization */
  width: 100%;
  text-align: center;
  padding: 20px;
  color: #e5e7eb;
}

.welcome-text {
  font-size: 2rem;
  margin-bottom: 20px;
}

.brief-description {
  margin-bottom: 40px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.feature-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f3f4f6;
  color: black;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  height: 200px; /* to make it square */
}

.feature-box:hover {
  background-color: #e5e7eb;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

.feature-text {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.feature-description {
  font-size: 0.9rem;
  position: absolute;
  bottom: 10px;
}
</style>

